import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_5/sub_3/slide1';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    header: mdx(frontmatter: {language: {eq: "EN"}, title: {eq: "slide-5-3-0_header"}}) {
      body
    }
    mdx(frontmatter: {language: {eq: "EN"}, title: {eq: "slide-5-3-0_content"}}) {
      body
    }
    file(relativePath: { eq: "chapter_5/sub_3/5-3-1-bg-mobile.png"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
    hoverDot: mdx(
      frontmatter: {
        language: { eq: "EN" }
        title: { eq: "5-3-1-mobile-dots" }
      }
    ) {
      exports {
        hoverInfo {
          insideText
          description
        }
      }
    },
    modal41221:  mdx(frontmatter: {language: {eq: "EN"}, title: {eq: "ukrainians-modals"}, selector: {eq: "modal41221"}}) {
      body
    },
    modal41222:  mdx(frontmatter: {language: {eq: "EN"}, title: {eq: "ukrainians-modals"}, selector: {eq: "modal41222"}}) {
      body
    },
    modal41223:  mdx(frontmatter: {language: {eq: "EN"}, title: {eq: "ukrainians-modals"}, selector: {eq: "modal41223"}}) {
      body
    },
    modal41224:  mdx(frontmatter: {language: {eq: "EN"}, title: {eq: "ukrainians-modals"}, selector: {eq: "modal41224"}}) {
      body
    },
    modal41225:  mdx(frontmatter: {language: {eq: "EN"}, title: {eq: "ukrainians-modals"}, selector: {eq: "modal41225"}}) {
      body
    },
    modal41226:  mdx(frontmatter: {language: {eq: "EN"}, title: {eq: "ukrainians-modals"}, selector: {eq: "modal41226"}}) {
      body
    },
    modal41227:  mdx(frontmatter: {language: {eq: "EN"}, title: {eq: "ukrainians-modals"}, selector: {eq: "modal41227"}}) {
      body
    },
    modal41228:  mdx(frontmatter: {language: {eq: "EN"}, title: {eq: "ukrainians-modals"}, selector: {eq: "modal41228"}}) {
      body
    },
    caption:   mdx(frontmatter: {language: {eq: "EN"}, title: {eq: "slide-4-6-1"}}) {
      body
  }
  }
  `);
  return (
    <Template query={query} title="Part III" />
  );
};


export default Slide;
