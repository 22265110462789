import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/en/chapter_5/sub_3/slide1';

import WrapperMobile from 'src/slides/mobile/en/chapter_5/sub_3/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from '../../../components/_shared/seo';

// desktop

const allSlides = [
  <Slide1 />,
];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Influence of the Battle of Warsaw on the fate of the world I the Battle of Warsaw" description="At the Vistula River, the Poles halted the flow of history for 20 years." lang="en" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

// mobile


const Mobile = () => (
  <>
    <SEO title="Influence of the Battle of Warsaw on the fate of the world I the Battle of Warsaw" description="At the Vistula River, the Poles halted the flow of history for 20 years." lang="en" />
    <WrapperMobile />
  </>
);

// export

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
